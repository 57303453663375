import { AreaContext } from '@/contexts/AreaContext';
import { TSearchParameters } from '@/contexts/AreaContext/AreaContext';
import { initialSearchParameters } from '@/contexts/AreaContext/defaultObjects';
import useApiCall from '@/hooks/useApiCall';
import {
  Button,
  Divider,
  Drawer,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import { useContext, useEffect, useState } from 'react';
import { SearchItem } from '../SearchItem';

type TFilters = {
  searchType: 'data_approval' | 'parts';
  action: 'PUT' | 'POST' | undefined;
  filters?: TSearchParameters | null;
  personalFiltersEnabled: boolean;
  setPersonalFiltersEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  personalFiltersId?: number;
  setPersonalFiltersId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
};

type TUpdate = {
  action: 'PUT' | 'POST';
  id?: number;
  filters: TSearchParameters;
  is_enabled: boolean;
};

const PersonalFilters = ({ setPersonalFiltersId, ...props }: TFilters) => {
  const { makeApiCall } = useApiCall();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [action, setAction] = useState(props.action);

  const { setSearchParameters, personalParameters, setPersonalParameters } =
    useContext(AreaContext);

  useEffect(() => {
    if (props.action) setAction(props.action);
  }, [props.action]);

  useEffect(() => {
    if (props.filters) setPersonalParameters(props.filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filters]);

  const handleCancel = () => {
    if (props.filters) setPersonalParameters(props.filters);
    setIsOpen(false);
  };

  const Update = (props: TUpdate) => {
    const update = async () => {
      try {
        return await makeApiCall({
          method: props.action,
          useAsFetch: true,
          url: import.meta.env.VITE_APP_API_URL + `/personal_filters`,
          payload: {
            id: props.id,
            filters: JSON.stringify(props.filters),
            is_enabled: props.is_enabled,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error occurred:', error);

        throw error;
      }
    };

    update()
      .then((result) => {
        if (props.action === 'POST') {
          //get id and update the id and the action to PUT
          setAction('PUT');
          setPersonalFiltersId(result.ID as number);
        }
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  const changeSearchToPersonalParams = () => {
    const objectKeys = <Obj extends object>(obj: Obj): (keyof Obj)[] => {
      return Object.keys(obj) as (keyof Obj)[];
    };

    type TNotSap = keyof TSearchParameters;

    const notSapKeys: TNotSap[] = [
      'application',
      'pdp_status',
      'engineering_status',
      'top_coat',
      'earmark',
      'casting_vendor',
      'source',
      'assembly_cluster',
      'life_cluster',
      'design_feature',
    ];

    const updatePersonalParameters = () => {
      const updatedParameters = { ...personalParameters };

      if (props.searchType === 'data_approval') {
        objectKeys(personalParameters).forEach((key) => {
          if (notSapKeys.includes(key)) {
            updatedParameters[key] = '';
          }
        });
      }
      setSearchParameters(updatedParameters);
    };

    updatePersonalParameters();
  };

  const handleConfirm = () => {
    if (action)
      Update({
        action: action,
        filters: personalParameters,
        id: props.personalFiltersId,
        is_enabled: props.personalFiltersEnabled,
      });

    changeSearchToPersonalParams();
    setIsOpen(false);
  };
  const handleReset = () => {
    setSearchParameters(initialSearchParameters);
  };

  const handleToggle = () => {
    if (action)
      Update({
        action: action,
        filters: personalParameters,
        id: props.personalFiltersId,
        is_enabled: !props.personalFiltersEnabled,
      });

    if (props.personalFiltersEnabled) {
      //setSearchParameters({ ...initialSearchParameters });
    } else {
      changeSearchToPersonalParams();
    }

    //if (props.personalFiltersEnabled) setIsOpen(false);
    props.setPersonalFiltersEnabled(!props.personalFiltersEnabled);
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <Stack direction="row" distribute="space-between">
        <div style={{ display: 'inline-block', marginTop: 10 }}>
          <Stack direction="row" gap={10}>
            <Button onClick={handleReset} type="link" allCaps={false}>
              Clear Search
            </Button>
            {action === 'PUT' && (
              <>
                <Divider orientation="vertical" />
                <Button
                  onClick={() => changeSearchToPersonalParams()}
                  type="link"
                  allCaps={false}
                >
                  Reset to my Pre-Filtering
                </Button>
              </>
            )}
          </Stack>
        </div>

        <div style={{ position: 'relative' }}>
          <Button
            size="small"
            type="primaryOutline"
            onClick={() => setIsOpen(true)}
            disabled={!action}
          >
            My Pre-Filtering
          </Button>
        </div>
      </Stack>
      <Drawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        fromRight={true}
        showCloseButton={true}
        width={500}
        isScrollable={true}
        onClose={() => handleCancel()}
        /*  width={props.width}
height={props.height}
pushContent={props.pushContent}
fromRight={props.fromRight}
showCloseButton={props.showCloseButton}
backgroundColor={props.backgroundColor}
closeOnClickOutside={props.closeOnClickOutside}
isScrollable={props.isScrollable}
showBackdrop={props.showBackdrop}
showShadow={props.showShadow}
backdropColor={props.backdropColor}
forceReloadWhenOpen={props.forceReloadWhenOpen} */
      >
        <div style={{ padding: 30 }}>
          <Typography tag="h4">My Pre-Filtering</Typography>

          <Typography tag="textdefault">
            Search filters will be previously filled according to your settings
          </Typography>

          <Divider padding={20} />

          <Stack direction="row" distribute="space-between">
            <Typography tag="textsmall">
              Filters are currently{' '}
              <b>{props.personalFiltersEnabled ? 'enabled' : 'disabled'}</b>
            </Typography>
            <Button type="primaryOutline" size="small" onClick={handleToggle}>
              {props.personalFiltersEnabled
                ? 'Disable Filters'
                : 'Enable Filters'}
            </Button>
          </Stack>
          <Divider padding={20} />

          <div
            style={{
              display: 'grid',
              gap: 20,
              gridTemplateColumns: '1fr 1fr',
              marginBottom: 30,
              /* opacity: !props.personalFiltersEnabled ? 0.2 : 1,
              pointerEvents: !props.personalFiltersEnabled ? 'none' : 'auto', */
            }}
          >
            <SearchItem
              key="p_part_types"
              itemKey="part_type"
              personalClassification={true}
            />
            <SearchItem
              key="p_frame_family"
              itemKey="frame_family"
              personalClassification={true}
            />
            <SearchItem
              key="p_part_owner"
              itemKey="part_owners"
              personalClassification={true}
            />
            <SearchItem
              key="p_application"
              itemKey="application"
              personalClassification={true}
            />
            <SearchItem
              key="p_pdp_status"
              itemKey="pdp_status"
              personalClassification={true}
            />
            <SearchItem
              key="p_sap_status"
              itemKey="sap_status"
              personalClassification={true}
            />
            <SearchItem
              key="p_assembly_cluster"
              itemKey="assembly_cluster"
              personalClassification={true}
            />
            <SearchItem
              key="p_life_cluster"
              itemKey="life_cluster"
              personalClassification={true}
            />

            <SearchItem
              key="p_engineering_status"
              itemKey="engineering_status"
              personalClassification={true}
            />
            <SearchItem
              key="p_base_material"
              itemKey="base_material"
              personalClassification={true}
            />
            <SearchItem
              key="p_internal_coating"
              itemKey="internal_coating"
              personalClassification={true}
            />
            <SearchItem
              key="p_external_coating"
              itemKey="external_coating"
              personalClassification={true}
            />
            <SearchItem
              key="p_thermal_barrier"
              itemKey="thermal_barrier"
              personalClassification={true}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ display: 'inline-block' }}>
              <Stack direction="row" gap={10}>
                <Button
                  type="primaryOutline"
                  onClick={handleCancel}
                  /* disabled={!props.personalFiltersEnabled} */
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirm}
                  /*         disabled={!props.personalFiltersEnabled} */
                >
                  Confirm
                </Button>
              </Stack>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default PersonalFilters;
